html {
  margin: 0;
  border: 0;
  box-sizing: content-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  border: 0;
  font-weight: 300;
  font-size: 16px;
  font-family: 'Ubuntu', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1E2832;
}

@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

a {
  color: #004aad;
  text-decoration: none;

}

a:hover {
  color: #004aad;
  text-decoration: none;
  opacity: 1 !important;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu", sans-serif;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #004aad;
  border-top-color: #d2f9eb;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.back-to-top {
  position: fixed;
  visibility: visible;
  opacity: 0.7;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #004aad;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  opacity: 1;
  background: #004aad;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

.breadcrumbs {
  padding: 20px 0;
  background-color: #fafaff;
  min-height: 40px;
  margin-top: 80px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 64px;
  }
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}

@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px 0;
  }
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li+li {
  padding-left: 10px;
}

.breadcrumbs ol li+li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }

  .breadcrumbs ol {
    display: block;
  }

  .breadcrumbs ol li {
    display: inline-block;
  }
}

.project-modal {
  max-width: 80vw !important;
}

.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header {
  background: #010483;
  border-radius: 0;
  justify-content: right;
}

.modal-project-content {
  color: #004aad;
}

.modal-description {
  font-weight: 400;
}

.btn-close-white,
.btn-close-white:active,
.btn-close-white:focus {
  margin: 0;
  padding: 0 10px;
  background-size: 0.5rem;
  font-size: 1rem;
  border: none;
  outline: none;
  background: none;
}

.btn-close svg {
  color: #fafafa;
}

.btn-clear {
  outline: none;
  box-shadow: none;
  background-color: none;
  background: none;
  border: none;
  border-radius: 0;
}

@media (max-width: 1023px) {
  .modal-project-content-container {
    flex-direction: column-reverse;
  }
}

@media (max-width: 768px) {
  .modal-content {
    height: 90vh;
  }

  .modal-body {
    overflow: scroll;
  }

  .project-modal {
    max-width: 90vw !important;
    margin: 0 auto;
  }
}